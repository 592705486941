// Date Created             		: August 31, 2012
// Author                   		: Minal Thavare, PCL
//
// Change History
//
// Date Modified            		: Month DD, YYYY
// Changed By               		: <name>
// Change Description       	    : <description of the change made>

var pupilData = [];
var errorFees = 'Please enter termly school fees';
var errorInvalidFees = 'Please enter valid termly school fees';
var schoolData = [];
var setproductchange = null;
$(document).ready(function () {
    $("#divProductDetails").hide();
    $("#schoolSearch").val("");
    $("#divTooEarly").hide();
    $("#resultsListContainer").attr('style', 'display:none;');
    var searchBoxId = document.getElementById("schoolSearch");
    if (searchBoxId != null) {
        searchBoxId.addEventListener('keydown', preventEnterToSubmitForm);
    }
    $("#btnBackPg").click("bind", function () {
        if (document.getElementById("ProductDetails_ProducerNameAndPostCode") != null) {
            if (document.getElementById("ProductDetails_ProducerNameAndPostCode").value != '') {
                window.location.href = "/Base/GoToBackPage";
            }
            else {
                window.location.href = "/Application/SchoolFeePlan";
            }
        }
        else {
            window.location.href = "/Base/GoToBackPage";
        }
    });

    $("#btnContinue").click("bind", function () {
        var errors;
        if ($('form')[0]['__MVC_FormValidation']) {
            errors = $('form')[0]['__MVC_FormValidation'].validate("submit");
        }
        if (fnFormValidation()) {
            // If validation is successful call the Controller to fetch address list .
            if (!$('form')[0]['__MVC_FormValidation'] || errors.length == 0) {
                $('#show_error_alert').attr('style', 'display:none;');
                fnBlockUI();
                $('form').submit();
                return false;
            }
            else {
                return false;
            }
        }
    });
    $("#schoolSearch").keyup(function () {
        var searchtext = $("#schoolSearch").val();
        if (searchtext != undefined && searchtext != null) {
            if (searchtext.length >= 3) {
                fnGetSchoolDetails(searchtext);
            }
            else {
                $("#resultsListContainer").attr('style', 'display:none;');
                $('#resultsList').empty();
            }
        }

    })
    $("#schoolSearch").click(function () {
        var searchtext = $("#schoolSearch").val();
        if (searchtext != '' && searchtext != undefined) {
            $("#resultsListContainer").attr('style', 'display:block;');
        }
        else {
            $('#resultsList').empty();
            $("#resultsListContainer").attr('style', 'display:block;');
            var mylist = document.getElementById('resultsList');
            mylist.insertAdjacentHTML('beforeend', '<li class="no-value">Please enter a school name to search</li >');
            mylist.insertAdjacentHTML('beforeend', '<div class="results_footer"><i class="fa-regular fa-circle-info"></i><p>If you\'re unable to find your school, please contact us by telephone: <a href="callto:03301239720">0330 123 9720</a> or email: <a href="mailto:sfp@pcl.co.uk">sfp@pcl.co.uk</a></p></div>');
        }
    })
    $('#schoolSearch').on('focus', function () {
        $('#resultsListContainer').show();
    });

    $('#schoolSearch').on('blur', function () {
        setTimeout(function () {
            $('#resultsListContainer').hide();
        }, 200);
    });
    $('input[type=radio][name=term_selection]').change(function () {
        var selectedProductId = $(this).val();
        $('#SelectedProductId').val(selectedProductId);
        setupPage(selectedProductId);
    });

    $('input[type=radio][name=pupil_selection]').change(function () {
        var id = $(this).attr('id');
        var numberOfPupils = id.substr(id.length - 1);
        renderPupilFeesPartialView(numberOfPupils);
    });

    // To keep old data after click edit term
    var localpupildata = $.parseJSON(localStorage.getItem('localPupilData'));
    if (localpupildata != null && localpupildata.length > 0) {
        pupilData = localpupildata;
        $('#NoOfPupils').val(pupilData.length);
    }

    fnLoadPupilFees();
    fnShowSchoolFees();

    $('#btnContinue').css({ "opacity": "0.5" });

    $("#btnSchoolPicker").click("bind", function () {
        $("#hfNoSchoolSelected").show();
        $("#hfSchoolSelected").hide();
        $("#selectedSchoolTerm").hide();
        $("#selectNoOfPupils").hide();
        $("#pupilFees").hide();
    });

    // To set the product, If the product is already selected 
    var hdnSelectedProductId = $('#hdnSelectedProductId').val();
    if (localStorage.getItem('resetProductFlag') != null && localStorage.getItem('resetProductFlag') == 'true') {
        hdnSelectedProductId = '';
    }

    if (hdnSelectedProductId != '' && hdnSelectedProductId != undefined) {
        $('input[name="term_selection"]').each(function (inx, ele) {
            if ($(ele).val() == hdnSelectedProductId) {
                $('input[name="term_selection"]')[inx].click();
            }
        });
    }
    setTimeout(fnValidateFormPostBack, 1);
    if (document.getElementById("ProductDetails_ProducerNameAndPostCode") != null) {
        if (document.getElementById("ProductDetails_ProducerNameAndPostCode").value != '') {
            $("#hfNoSchoolSelected").hide();
            $("#hfSchoolSelected").show();
            $("#selectedSchoolTerm").show();
            $("#selectNoOfPupils").show()
            $("#pupilFees").show();
        }
        else {
            $("#hfNoSchoolSelected").show();
            $("#hfSchoolSelected").hide();
            $("#selectedSchoolTerm").hide();
            $("#selectNoOfPupils").hide();
            $("#pupilFees").hide();
        }
    }
    var resetProducerName = localStorage.getItem('resetProducerName');
    setproductchange = resetProducerName;
    if ((resetProducerName != undefined || resetProducerName != null) && document.getElementById("ProductDetails_ProducerNameAndPostCode") != null) {
        if (resetProducerName == 'true') {
            $("#hfNoSchoolSelected").show();
            $("#hfSchoolSelected").hide();
            $("#selectedSchoolTerm").hide();
            $("#selectNoOfPupils").hide();
            $("#pupilFees").hide();
            localStorage.setItem('resetProducerName',false);
        }
        else {
            $("#hfNoSchoolSelected").hide();
            $("#hfSchoolSelected").show();
            $("#selectedSchoolTerm").show();
            $("#selectNoOfPupils").show()
            $("#pupilFees").show();
        }
    }
});

function fnContinueButtonDisableCheck() {
    var selectedNumberOfPupils = $('#SelectedNoOfPupils').val()
    if (selectedNumberOfPupils !== undefined && selectedNumberOfPupils !== null && selectedNumberOfPupils !== '' && selectedNumberOfPupils == '0') {
        $("#btnContinue").attr("disabled", "disabled").addClass("disabled");
    }
}

function fnShowSchoolFees() {
    var selectedNumberOfPupils = $('#SelectedNoOfPupils').val()
    if (selectedNumberOfPupils !== undefined && selectedNumberOfPupils !== null && selectedNumberOfPupils !== '' && selectedNumberOfPupils != '0') {
        $("#pupilFees").show();
    }
}

function fnLoadPupilFees() {
    var numberOfPupils = $('#NoOfPupils').val()
    var resetProducerName = localStorage.getItem('resetProducerName');
    if (numberOfPupils !== undefined && numberOfPupils !== null && numberOfPupils !== ''  && resetProducerName != 'true') {
        $('#pupil_selection' + numberOfPupils).prop('checked', true);
        renderPupilFeesPartialView(numberOfPupils);
        $("#totalTermFees").autoNumeric({ mNum: 8, mDec: 2 });
        var totalFees = 0.00;
        $('.pupilTermFees').each(function () {
            totalFees += parseFloat(stripCurrency("", $(this).val()));
        });
        $('#totalTermFees').val(fnFormatCurrency(totalFees.toFixed(2)));
    }

    var selectedProductId = $('#ProductDetails_SelectedProductId').val()
    if (selectedProductId !== undefined && selectedProductId !== null && selectedProductId !== '') {
        var selectedTerm = $('input[type = radio][name = term_selection]').filter('[value=' + selectedProductId + ']');
        if (selectedTerm.length) {
            selectedTerm.prop('checked', true);
        }
        setupPage(selectedProductId);
    }
}
var selectedProductFlag = false;
function setupPage(selectedProductId) {
    if (selectedProductId != "") {
        var obj = { selectedProductId: selectedProductId };
        AjaxCommunication.CreateRequest(
            this.window,
            'POST',
            "/Application/SelectedVariantDetails",
            '',
            obj,
            function (result) {
                if (result.Result.Status) {
                    selectedProductFlag = true;
                    fnChangeColor();
                    $("#applyNowBtn").removeAttr("disabled").removeClass("disabled");
                    $('#hiddenFieldAction').val(0);
                    $("#applyNowSection").show();
                    $("#divTooEarly").hide();
                    if (setproductchange==null || setproductchange == 'false') {
                        $("#selectNoOfPupils").show();
                        $("#pupilFees").show();
                    }
                    $("#lblNoOfCollections").text(result.Result.Data.NumberOfCollections);
                    var startDate = new Date(parseInt(result.Result.Data.ProductStartDate.substr(6)));
                    $("#lblProductStartDate").text(startDate.format("dd/MM/yyyy"));
                }
                else {
                    selectedProductFlag = false;
                    fnChangeColor();
                    $("#applyNowBtn").attr("disabled", "disabled").addClass("disabled");
                    $('#hiddenFieldAction').val(1);
                    $("#applyNowSection").hide();
                    $("#divTooEarly").show();
                    $("#selectNoOfPupils").hide();
                    $("#pupilFees").hide();

                    var tooEarlyText = $("#hfTooEarly").val();
                    $("#tooEarlyMessage").html(tooEarlyText.replace('{0}', result.Result.Data.ProductTermGroupName)
                        .replace('{1}', GetDateOrdinalSuperScript(result.Result.Data.EarliestApplicationDateOrdinal) + '&nbsp;' + result.Result.Data.EarliestApplicationDateMonthName)
                        .replace('{2}', GetDateOrdinalSuperScript(result.Result.Data.LatestApplicationDateOrdinal) + '&nbsp;' + result.Result.Data.LatestApplicationDateMonthName)
                        .replace('{3}', result.Result.Data.ProductTermGroupName));
                }
            });
    }
    else {
        fnChangeColor();
        $("#divProductDetails").hide();
        $("#divTooEarly").hide();
        return false;
    }
}

function renderPupilFeesPartialView(numberOfPupils) {
    if (numberOfPupils != "") {
        var obj = { numberOfPupils: numberOfPupils };
        AjaxCommunication.CreateRequest(
            this.window,
            'GET',
            "/Application/GetPupilFeesPartialView",
            '',
            obj,
            function (result) {
                fnChangeColor();
                $("#divPupilFeesControl").html(result);
                $("#pupilFees").show();
                $(".pupilTermFees").autoNumeric({ mNum: 8, mDec: 2 });
                $("#totalTermFees").autoNumeric({ mNum: 8, mDec: 2 });
                var totalFees = 0.00;
                if (parseInt(numberOfPupils) < pupilData.length) {
                    pupilData.splice(numberOfPupils, pupilData.length);
                }
                $('.pupilTermFees').each(function (i, e) {
                    if (pupilData.length > 0) {
                        $.each(pupilData, function (index, value) {
                            if (e.id == value.Id) {
                                $(e).val(value.value);
                            }
                        });
                    }
                    totalFees += parseFloat(stripCurrency("", $(this).val()));
                    $(this).val(fnFormatCurrency($(this).val()));
                    fnChangeColor();
                });
                $('.pupilTermFees').each(function (index, element) {
                    $(element).blur(function () {
                        var enteredSchoolFees = $(element).val();
                        if (enteredSchoolFees === '' || enteredSchoolFees === undefined) {
                            $('#' + element.id).addClass('is-invalid');
                            $('.invalid-feedback').text(errorFees);
                            $('#' + $('.invalid-feedback')[index].id).show();
                        }
                        else if (parseFloat(enteredSchoolFees) < 1) {
                            $('#' + element.id).addClass('is-invalid');
                            $('.invalid-feedback').text(errorInvalidFees);
                            $('#' + $('.invalid-feedback')[index].id).show();
                        }
                        else {
                            $('#' + element.id).removeClass('is-invalid');
                            $('#' + $('.invalid-feedback')[index].id).hide();
                        }
                    });
                });
                $('#totalTermFees').val(fnFormatCurrency(totalFees.toFixed(2)));
                $('input.pupilTermFees').on('focusout', function () {
                    $(".pupilTermFees").autoNumeric({ mNum: 8, mDec: 2 });
                    $(this).val(fnFormatCurrency($(this).val()));
                    var totalFees = 0.00;
                    $('.pupilTermFees').each(function (i, e) {
                        totalFees += parseFloat(stripCurrency("", $(this).val()));
                        $(this).val(fnFormatCurrency($(this).val()));
                        fnChangeColor();
                        if ($(e).val() != '' && $(e).val() != undefined && parseFloat($(e).val()) >= 1) {
                            if (pupilData.find(x => x.Id === e.id)) {
                                const indexOfObject = pupilData.findIndex(object => {
                                    return object.Id === e.id;
                                });
                                pupilData.splice(indexOfObject, 1);
                                pupilData.push({ Id: e.id, value: $(e).val() })
                            }
                            else {
                                pupilData.push({ Id: e.id, value: $(e).val() })
                            }
                        }
                    });
                    localStorage.removeItem('localPupilData');
                    localStorage.setItem('localPupilData', JSON.stringify(pupilData));
                    $('#totalTermFees').val(fnFormatCurrency(totalFees.toFixed(2)));
                });
                $('#SelectedNoOfPupils').val(numberOfPupils);
            });
    }
}

function fnTermSelection() {
    if ($("input[type='radio'][name='term_selection']:checked").val() != undefined
        && $("input[type='radio'][name='term_selection']:checked").val() != ''
        && selectedProductFlag == true)
        return true;
    else
        return false;
}

function fnTermFeesCheck() {
    var status = false;
    if ($('.pupilTermFees').length > 0) {
        $('.pupilTermFees').each(function (index, val) {
            if ($(this).val() == '' || $(this).val() == undefined || parseFloat($(this).val()) < 1) {
                status = false;
                return status;
            }
            else {
                status = true;
                return status;
            }
        });
    }
    else {
        status = false;
        return status;
    }
    return status;
}

function fnChangeColor() {
    if (fnTermSelection() == true && fnTermFeesCheck() == true) {
        $('#btnContinue').css({ "opacity": "1.0" });
        return true;
    }
    else {
        $('#btnContinue').css({ "opacity": "0.5" });
        return false;
    }
}

// button active and inActive 
function fnFormValidation() {
    if (fnTermSelection() == true && fnTermFeesCheck() == true) {
        return true;
    }
    else {
        $('#show_error_alert').attr('style', 'display:block;');
        $(window).scrollTop(0);
        if ($('.pupilTermFees').length > 0) {
            $('.pupilTermFees').each(function (index, element) {
                if ($(element).val() === '' || $(element).val() === undefined) {
                    $('#' + element.id).addClass('is-invalid');
                    $('.invalid-feedback').text(errorFees);
                    $('#' + $('.invalid-feedback')[index].id).show();
                }
            });
        }
        return false;
    }
}

// Method to strip currency from currency format value.
function stripCurrency(ii, value, options) { // public function that stripes the format and converts decimal seperator to a period
    if (value == null || value == undefined)
        return 0;
    var opts = $.extend({}, $.fn.autoNumeric.defaults, options);
    var io = $.metadata ? $.extend({}, opts) : opts;
    io.mDec = isNaN(io.mDec * 1) ? $('#' + io.mDec).val() * 1 : io.mDec * 1; // decimal places
    var iv = value;
    iv = iv.replace(io.aSign, '').replace('\u00A0', '');
    var reg = new RegExp('[^' + '\\-' + io.aNum + io.aDec + ']', 'gi'); // regular expreession constructor
    iv = iv.replace(reg, ''); //deletes all characters that are not permitted in this field
    var nSign = '';
    if (iv.charAt(0) == '-') { //Checks if the iv (input Value)is a negative value
        nSign = (iv * 1 === 0) ? '' : '-'; //determines if the value is zero - if zero no negative sign
        iv = iv.replace('-', ''); // removes the negative sign will be added back later if required                  
    }
    iv = iv.replace(io.aDec, '.');
    if (iv * 1 > 0) {
        while (iv.substr(0, 1) == '0' && iv.length > 1) {
            iv = iv.substr(1, 9999);
        }
    }
    iv = (iv.lastIndexOf('.') === 0) ? ('0' + iv) : iv;
    iv = (iv * 1 === 0) ? '0' : iv;
    return nSign + iv;
};

function fnValidateFormPostBack() {
    var isPostBack = $('#hfIsPostback').val();
    if (isPostBack === "1") {
        if ($('form')[0]['__MVC_FormValidation']) {
            $('form')[0]['__MVC_FormValidation'].validate("submit");
        }
    }
}

function fnGetSchoolDetails(searchtext) {
    $('#resultsList').empty();
    var token = $('input[name="__RequestVerificationToken"]').val();
    $.ajax({
        url: "/Application/SchoolDetails?name=" + searchtext,
        type: 'GET',
        data: { Headers:token },
        async:false,
    }).done(function (res) {
        schoolData = res;

    }).fail(function (resp) {
        $("#resultsListContainer").attr('style', 'display:block;');
    })
    $("#resultsListContainer").attr('style', 'display:block;');
    for (j = 0; j < schoolData.length; j++) {
        var mylist = document.getElementById('resultsList');
        mylist.insertAdjacentHTML('beforeend', '<li><a onclick="fnSelectSchoolName(\'' + schoolData[j].ProductIdentifier + '\')">' + schoolData[j].SchoolName + ', ' + schoolData[j].PostCode + '</a></li>');
        
    }
    if (schoolData.length == 0) {
        var mylist = document.getElementById('resultsList');
        mylist.insertAdjacentHTML('beforeend', '<li class="no-results">No results found, please type the name of your school</li >');
    }
    var mylist = document.getElementById('resultsList');
    mylist.insertAdjacentHTML('beforeend', '<div class="results_footer"><i class="fa-regular fa-circle-info"></i><p>If you\'re unable to find your school, please contact us by telephone: <a href="callto:03301239720">0330 123 9720</a> or email: <a href="mailto:sfp@pcl.co.uk">sfp@pcl.co.uk</a></p></div>');
    document.getElementById("schoolSearch").focus();
}

function fnSelectSchoolName(productIdentifier) {

    $.ajax({
        url: "/Application/SchoolFees?productIdentifier=" + productIdentifier,
        type: 'GET',
        async: false,
    }).done(function (res) {
    }).fail(function (resp) {
    })
    if (productIdentifier != null) {
        $("#hfNoSchoolSelected").hide();
        $("#hfSchoolSelected").show();
        $("#selectedSchoolTerm").show();
        $("#selectNoOfPupils").show()
        $("#pupilFees").show();
        $("#resultsListContainer").hide();
    }
    else {
        $("#hfNoSchoolSelected").show();
        $("#hfSchoolSelected").hide();
        $("#selectedSchoolTerm").hide();
        $("#selectNoOfPupils").hide();
        $("#pupilFees").hide();
    }
    localStorage.setItem('resetProductFlag', true);
    var url = location.origin;
    var pathname = location.pathname;
    var hash = location.hash;
    location = url + pathname + hash;
}

function preventEnterToSubmitForm(event) {
    if (event.key === "Enter") {
        {
            event.preventDefault();
            return false;
        }
    }
}